import React, { useState } from 'react';
import { Link } from 'gatsby';
import './style.scss';
import xclose from '../images/x.svg';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <nav className='navbar' role='navigation' aria-label='main navigation'>
      <button className="navbar-burger" onClick={toggleMenu}>
        <span aria-hidden='true'></span>
        <span aria-hidden='true'></span>
        <span aria-hidden='true'></span>
      </button>
      <div id='mainNav' className={`navbar-menu ${menuOpen ? 'menu-open' : 'menu-closed'}`}>
        <div className='navbar-start'>
          <Link activeClassName="active" to='/'>Home</Link>
          <Link activeClassName="active" to='/brand'>Brand</Link>
          <Link activeClassName="active" to='/logo'>Logo</Link>
          <Link activeClassName="active" to='/color'>Color</Link>
          <Link activeClassName="active" to='/typography'>Typography</Link>
        </div>
        <button className="closebtn" onClick={toggleMenu}><img src={xclose}/></button>
      </div>
    </nav>
  );
};
export default Navbar;