import React from 'react';

import './style.scss';
import Header from './header';
import Brandpage from './brandpage';
import Footnav from './footnav'
import Footer from './footer';

const BrandLayout = ({ children }) => (
	<div>
		<Header />
		<Brandpage />
		<Footer />
	</div>
);

export default BrandLayout;
