import React from 'react';
import { Link } from 'gatsby';
import homeicon from '../images/home.svg';
import rarr from '../images/rarr.svg';
import './style.scss';
import larr from '../images/larr.svg';

var pageTitle = "Brand Tenets";


const Homecontent = () => (
	<div>

		<section className="pagetitle brandpage earthwarebg">
			<div className="container">
			<h1 className="title white capitol">{pageTitle}</h1>
			</div>
		</section>

		<section className="pbt-section section">
			<div className="container">
				<div className="columns is-justify-content-space-between">
					<div className="column is-half">
						<h2 className="is-size-1 capitol steel">Building Our Identity</h2>
						<p>Understanding and communicating what our business stands for is paramount to a company’s brand. On this page we outline the thing that make us who we are.</p>
					</div>
					<div className="column is-one-third">
						<h5 className="is-uppercase has-text-weight-bold mont is-size-7 tracked pb-4">Components</h5>
						<div className="columns">
							<div className="column">
								<ul className="pagesubnav">
									<li><a href="#vision">Vision Statment</a></li>
									<li>
										<a href="#brand">Brand Essence</a>
										<ul>
											<li><a href="#what">What We Do</a></li>
											<li><a href="#how">How We Do It</a></li>
											<li><a href="#why">Why We Do It</a></li>
										</ul>
									</li>
								</ul>
							</div>
							<div className="column">
								<ul className="pagesubnav">
									<li><a href="#mission">Mission Statement</a></li>
									<li><a href="#promise">Brand Promise</a></li>
									<li><a href="#tag">Tagline</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section id="vision" className="whitebg pbt-section section">
			<div className="container">
			<div className="columns is-justify-content-center">
				<div className="column is-two-thirds has-text-centered">
					<h2 className="is-size-1 capitol steel mb-3">Vision</h2>
					<h3 className="capitol bbared subsubtitle"><nobr>Transform the present.</nobr> <nobr>Shape the future.</nobr><br/><nobr>Make tomorrow a better place.</nobr></h3>
				</div>
				<div className="column is-one-third">
					<div className="sidenote">
					<h5 className="is-uppercase has-text-weight-bold mont is-size-7 tracked pb-4">Definition</h5>
					<p className="caption">Our vision is a declaration of our objectives and goals for our future, intended to guide the company forward.</p>
					</div>
				</div>
			</div>
			</div>
		</section>

		<section id="brand" className="stonebg pbt-section-small-large has-text-centered section">
			<h2 className="is-size-1 capitol pastel mb-3">Brand Essence</h2>
			<p className="white callout has-text-weight-semibold">Our vision is broken down into <nobr>three essential pieces:</nobr><br/>what we do, how we do it, and why we do it.</p>
		</section>

		<section id="what" className="whitebg pbt-section-small-large section">
			<div className="container">
				<h4 className="vertalign iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">What We Do</h4>
				<div className="columns is-align-items-center">
					<div className="column">
						<h3 className="capitol bighead lineheight1">Transform<br/> the <i>present.</i></h3>
					</div>
					<div className="column">
						<h3 className="bbared capitol is-size-2 mb-3">We shape places.</h3>
						<p>Understanding and communicating what our business stands for is paramount to a company’s brand. On this page we outline the thing that make us who we are.</p>
					</div>
				</div>
			</div>
		</section>

		<section id="how" className="pbt-section-small-large section">
			<div className="container">

				<h4 className="vertalign iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">How We Do It</h4>
				<div className="columns is-align-items-center">
					<div className="column is-two-thirds">
						<h3 className="capitol bighead lineheight1 mb-5">Shape the <i>future.</i></h3>
						<p>We shape the future by transforming present spaces into tomorrow’s places. To “shape” is to influence how a place is perceived and experienced physically, intentionally, and psychologically. These elements are represented by the 3Ps of placemaking: presence, purpose and persona.</p>
					</div>
				</div>

				<div className="spacer py-5"></div>

				<div className="is-flex is-align-items-center">
					<div className="is-flex-grow-1"><hr/></div>
					<div className="is-size-5 is-uppercase has-text-weight-semibold mont pl-2 pr-2">THE 3PS</div>
					<div className="is-flex-grow-1"><hr/></div>
				</div>

				<div className="columns has-text-centered py-6">
					<div className="column">
						<h3 className="bbared capitol is-size-2 mb-3">Presence</h3>
						<p>The physical embodiment of a place<br/>—how it looks.</p>
					</div>
					<div className="column">
						<h3 className="bbared capitol is-size-2 mb-3">Purpose</h3>
						<p>The intended usage of a place<br/>—how it functions.</p>
					</div>
					<div className="column">
						<h3 className="bbared capitol is-size-2 mb-3">Persona</h3>
						<p>The mood or ambience of a place<br/>—how it feels.</p>
					</div>
				</div>

				<hr />

				<div className="spacer py-6"></div>

				<div className="threep">
					<div className="processtag">
						<h4 className="capitol is-size-3 mb-2">Our Process</h4>
						<p className="callout mb-5">How we shape the experience</p>
						<svg width="80" height="20" viewBox="0 0 80 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M70.7688 1.55403L76.6085 8.88263L-3.88549e-07 8.88261L-3.1084e-07 11.1033L76.6085 11.1033L70.7688 18.4319L72.0371 19.9861L80 9.99304L72.0371 1.78873e-06L70.7688 1.55403Z" fill="#81B29A"/>
						</svg>
					</div>
				</div>

			</div>
		</section>

		<section id="why" className="whitebg pbt-section-small-large section">
			<div className="container">
				<h4 className="vertalign iconh sectitle is-uppercase bbared tracked has-text-weight-bold mont is-size-7 mb-6">Why We Do It</h4>
				<div className="columns is-align-items-center">
					<div className="column">
						<h3 className="capitol bighead lineheight1">Make tomorrow<br/> a better <i>place.</i></h3>
					</div>
					<div className="column">
						<h3 className="bbared is-size-5 mb-3 has-text-weight-bold">We believe in the power of transformation.</h3>
						<p>We believe that by shaping objects and the experiences around those objects we’re capable of improving lives, neighborhoods and communities. While change is inevitable it can also be innovative, creative, and productive—all that’s required is a nuturing hand. We shape places and, in turn, they shape us.</p>
					</div>
				</div>
			</div>
		</section>

		<section id="mission" className="pbt-section-small section">
			<div className="container">
			<div className="columns is-justify-content-center">
				<div className="column is-two-thirds has-text-centered">
					<h2 className="is-size-1 capitol steel mb-3">Mission</h2>
					<h3 className="capitol bbared subsubtitle">To shape and inspire transformation one person,<br/> one project, and one community at a time.</h3>
				</div>
				<div className="column is-one-third">
					<div className="sidenote">
					<h5 className="is-uppercase has-text-weight-bold mont is-size-7 tracked pb-4">Definition</h5>
					<p className="caption">The concise expression of our company’s values, goals and reason for existing.</p>
					</div>
				</div>
			</div>
			</div>
		</section>

		<section id="promise" className="whitebg pbt-section-small section">
			<div className="container">
			<div className="columns is-justify-content-center">
				<div className="column is-two-thirds has-text-centered">
					<h2 className="is-size-1 capitol steel mb-3">Brand Promise</h2>
					<h3 className="capitol bbared subsubtitle">Building equity by shaping community.</h3>
				</div>
				<div className="column is-one-third">
					<div className="sidenote">
					<h5 className="is-uppercase has-text-weight-bold mont is-size-7 tracked pb-4">Definition</h5>
					<p className="caption">Our promise sets the value and expectation for our client’s experience working with us.</p>
					</div>
				</div>
			</div>
			</div>
		</section>

		<section id="tag" className="pbt-section-small section">
			<div className="container">
			<div className="columns is-justify-content-center">
				<div className="column is-two-thirds has-text-centered">
					<h2 className="is-size-1 capitol steel mb-3">Tagline</h2>
					<h3 className="capitol bbared subsubtitle">The shape of things to come.</h3>
				</div>
				<div className="column is-one-third">
					<div className="sidenote">
					<h5 className="is-uppercase has-text-weight-bold mont is-size-7 tracked pb-4">Definition</h5>
					<p className="caption">The most distilled encapsulation of our business. Shown in the logo, it is our most powerful descriptor.</p>
					</div>
				</div>
			</div>
			</div>
		</section>

		<div className='footer-nav'>
			<nav className='navbarfoot'>
				<div className='container spotgrid'>
				<Link className='prevpage' to='/'>
					<img src={larr} alt='Left Arrow' /> Home 
					</Link>
					<div className="has-text-centered">
					<Link className='homebut' to='/'>
						<img src={homeicon} alt='Home' />
					</Link>
					</div>
					<Link className='nextpage' to='/logo'>
						Logo <img src={rarr} alt='left Arrow' />
					</Link>
				</div>
			</nav>
		</div>

	</div>


);

export default Homecontent;
