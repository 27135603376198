import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import './style.scss';
import larr from '../images/larr.svg';
import rarr from '../images/rarr.svg';
import homeicon from '../images/home.svg';

const pages = {
  '/': {
    title: 'Home',
    path: '/',
    next: '/brand',
    previous: '/typography',
  },
  '/brand': {
    title: 'Brand',
    path: '/brand',
    next: '/logo',
    previous: '/',
  },
  '/logo': {
    title: 'Logo',
    path: '/logo',
    next: '/color',
    previous: '/brand',
  },
	'/color': {
    title: 'Color',
    path: '/color',
    next: '/typography',
    previous: '/logo',
  },
	'/typography': {
    title: 'Typography',
    path: '/typography',
    next: '/',
    previous: '/color',
  },
};
const Footnav = props => {
  const location = useLocation()
  const currentPathObj = pages[location.pathname]
	return (
    <div className='footer-nav'>
      <nav className='navbarfoot'>
        <div className='container spotflex'>
						{/* <Link className='prevpage' to={pages[currentPathObj.previous].path}> 
            <img src={larr} alt='left Arrow' /> {pages[currentPathObj.previous].title}
           </Link> */}
          <Link className='homebut' to='/'>
            <img src={homeicon} alt='Home' />
          </Link>
          {/* <Link className='nextpage' to={pages[currentPathObj.next].path}>
            {pages[currentPathObj.next].title} <img src={rarr} alt='left Arrow' />
          </Link> */}
        </div>
      </nav>
    </div>
  );
};
export default Footnav;