import React from 'react';
import { Link } from 'gatsby';

import './style.scss';
import Navbar from './navbar';
import bbabs from '../images/bba_bs-logo.svg';

const Header = ({ siteTitle }) => (
	<div className="titlebar">
	<div className="container">
	<div className="logobs">
	<Link to="/"><img className="logo" src={bbabs} alt="BBA Brand Standards" /></Link>
	</div>
	<div className="navhelp">
	<Navbar />
	</div>
	</div>
	</div>
	
	);
	
	export default Header;
	